<template>
  <div>
    <h1>HUABYTE FileFTP</h1>
    <el-row>
      <div class="el-form-item__content">
        <el-upload
          ref="upload"
          accept=".zip,.rar"
          class="upload-demo"
          :file-list="fileList"
          :auto-upload="false"
          drag
          action="#"
          :multiple="false"
          :before-upload="beforeUpload"
          :http-request="uploadHttpRequest"
          :on-remove="fileRemove"
          :on-change="fileChange"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          <div class="el-upload__tip" slot="tip">只能是zip格式，且不超过30MB，一次只能上传一个文件</div>
          <div class="el-upload__tip" slot="tip">请勿恶意传输、DDOS攻击...</div>
        </el-upload>
      </div>
    </el-row>
    <el-row>
      <el-button size="small" @click="closeDialog">取 消</el-button>
      <el-button type="primary" size="small" @click="submitUpload"
        >上 传</el-button>
    </el-row>
  </div>
</template>

<script>
import NProgress from 'nprogress';

export default {
  data() {
    return {
      fileList: [],
      // downloadRes: {},
    };
  },
  methods: {
    // 上传文件之前的钩子：判断上传文件格式、大小等，若返回false则停止上传
    beforeUpload(file) {
      //文件类型
      console.log(file)
      console.log(file.type)
      const isType = file.type === "application/rar";
      const isTypeComputer =
        file.type ===
        "application/x-zip-compressed";
      const fileType = isType || isTypeComputer;
      if (!fileType) {
        this.$message.error("上传文件只能是zip格式！");
      }

      // 文件大小限制为10M
      const fileLimit = file.size / 1024 / 1024 < 30;
      if (!fileLimit) {
        this.$message.error("上传文件大小不超过30M！");
      }
      return fileType && fileLimit;
    },
    // 自定义上传方法，param是默认参数，可以取得file文件信息，具体信息如下图
    uploadHttpRequest(param) {
      setTimeout(() => {
        if (!this.show) NProgress.start();
      }, 100);

      const formData = new FormData(); //FormData对象，添加参数只能通过append('key', value)的形式添加
      formData.append("file", param.file); //添加文件对象
      formData.append("uploadType", this.rulesType);
      const url = 'https://fileadm.huabyte.com/upload'; //上传地址
      this.$http
        .post(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          }
        })
        .then((res) => {
          console.log(res)
          const {
            data: { code },
          } = res;
          if (code === 0) {
            this.$message.success('上传成功');
            param.onSuccess(); // 上传成功的文件显示绿色的对勾
            NProgress.done();
            // this.uploadMark = mark;
          }
          if (code === 100) {
            this.$message.warning('检查文件命名是否正确');
            param.onError(); // 上传成功的文件显示绿色的对勾
            NProgress.done();
            // this.uploadMark = mark;
          }
          // return this.countData(this.uploadMark); //根据mark值调用统计结果接口，返回一个promise以便进行链式调用
        })
        /* .then((res) => {
          //链式调用，调用统计结果的响应
          const {
            data: { code, data },
          } = res;
          if (code === 0) {
            console.log("统计结果", data);
          }
        }) */
        .catch((err) => {
          this.$message.error('上传失败');
          console.log("失败", err);
          param.onError(); //上传失败的文件会从文件列表中删除
          NProgress.done();
        });
    },
    // 统计结果
    /* countFile(mark) {
      return new Promise((resolve, reject) => {
        this.$http
          .get(`/operation/ruleImport/countData?mark=${mark}`)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    }, */
    // 点击上传：手动上传到服务器，此时会触发组件的http-request
    submitUpload() {
      this.$refs.upload.submit();
    },
    // 文件发生改变
    fileChange(file, fileList) {
      if (fileList.length > 0) {
        this.fileList = [fileList[fileList.length - 1]]; // 展示最后一次选择的文件
      }
    },
    // 移除选择的文件
    fileRemove(file, fileList) {
      if (fileList.length < 1) {
        this.uploadDisabled = true; //未选择文件则禁用上传按钮
      }
    },
    // 取消
    closeDialog() {
      this.$refs.upload.clearFiles(); //清除上传文件对象
      this.fileList = []; //清空选择的文件列表
      this.$emit("close", false);
    },
  },
};
</script>

<style lang="less" scoped>
h1 {
  color: #409EFF;
}
</style>